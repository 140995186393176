
import React, { Component } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { faqHeader } from 'images/hulp/'
import FaqDropdown from 'components/faq-dropdown'
import Layout from '../../layouts'

const allCategories = [
  {
    name: 'Beginnen met Employes',
    tagline: 'Van start',
    id: 'beginnen-met-employes',
    subtext: 'Met Employes heb je de zekerheid dat je salaris-, HR- en verlofadministratie goed is geregeld. De online oplossing is eenvoudig in gebruik, waardoor je snel zelf aan de slag kunt.',
    questions: [
      {
        title: 'Hoe lang is de proefperiode precies?',
        id: 'hoe-lang-proefperiode',
        content: 'Na het aanmaken van een account, kun je Employes zo lang als je wilt gratis en vrijblijvend uitproberen in de vorm van een demo-versie. Pas wanneer je aangeeft dat je daadwerkelijk met Employes aan de slag wil, zetten we je account op actief.',
        link: ''
      },
      {
        title: 'Hoe ga ik van start met Employes?',
        id: 'hoe-start-ik',
        content: 'Nadat je een account hebt aangemaakt, begeleiden we je stap voor stap door de applicatie. Tijdens deze stappen, kun je alle relevante gegevens voor je salarisadministratie invullen. Daarna kun je binnen een paar minuten al de eerste loonstrook bekijken. Wil je daarna overstappen? Kies het pakket dat bij je past en klik vervolgens op "account activeren".',
        link: ''
      },
      {
        title: 'Kan ik meerdere inlogs krijgen voor Employes?',
        id: 'meerdere-inlog',
        content: 'Ja, dat kan. Als je met meerdere medewerkers wilt werken in Employes, dan is dat geen probleem. Ook kun je je accountant en werknemers toegang geven. Via het rollen- en gebruikersbeheer kun je zelf aangeven wie toegang krijgt tot welk onderdeel van de beveiligde online omgeving.',
        link: ''
      }
    ]
  },
  {
    name: 'Kiezen voor Employes',
    tagline: 'Overstappen',
    id: 'overstappen',
    subtext: 'Van de ene salarisadministratie naar de andere overstappen, kan flink wat werk opleveren. Dat is niet het geval bij Employes. Wij zijn in staat om de overstap geruisloos en soepel te laten verlopen.',
    questions: [
      {
        title: 'Hoe werkt het overstappen?',
        id: 'hoe-werkt-overstappen',
        content: 'Om ervoor te zorgen dat je met Employes aan de slag kunt, hebben wij de volgende informatie nodig: de meest recente loonstroken van je werknemer(s), de laatste loonaangifte en de loonjournaalpost. Ons team regelt dat die informatie in de applicatie wordt ingeladen zodat je de salaris van de volgende maand automatisch in Employes kunt verwerken.',
        link: ''
      },
      {
        title: 'Opzeggen bij je huidige salarisadministrateur',
        id: 'opzeggen-salarisadministrateur',
        content: 'Het is belangrijk dat je je huidige salarisadministrateur goed informeert over het moment dat je opstapt. Zo kun je voorkomen dat er dubbel werk ontstaat of dat er iets verkeerd gaat.',
        link: ''
      },
      {
        title: 'Heb ik naast Employes nog een salarisadministrateur nodig?',
        id: 'is-een-salarisadministrateur-nodig',
        content: 'Nee. De applicatie neemt in principe werk van de salarisadministrateur uit handen. Denk aan de automatische verwerking van vrije dagen, verzuim en vakantiegeld en de opzet van loonstroken. Verder kunnen wij je adviseren over specifieke zaken die voor jouw bedrijf aan de orde zijn.',
        link: ''
      },
      {
        title: 'Voldoet Employes aan de AVG?',
        id: 'employes-avg',
        content: 'Ja, met Employes voldoe je aan de regels die gelden voor de verwerking van persoonsgegevens op basis van de Algemene verordening gegevensbescherming.',
        link: ''
      }
    ]
  },
  {
    name: 'Geen verborgen kosten',
    tagline: 'Tarieven',
    id: 'tarieven',
    subtext: 'Bij Employes weet je precies waar je aan toe bent. Onze pakketten zijn volledig ingericht met alle salariszaken waar het MKB mee te maken krijgt. Kortom, er is nooit sprake van verborgen kosten.',
    questions: [
      {
        title: 'Wat zijn de kosten voor een bedrijf?',
        id: 'wat-zijn-de-kosten-voor-bedrijf',
        content: 'Voor bedrijven met personeel zijn er twee pakketten. Kies je voor: Salaris, dan betaal je € 29,- per maand + € 6,50 per werknemer per maand. Kies je voor: Salaris & HR, dan betaal je € 39,- per maand + € 8,- per werknemer per maand. Als je een DGA bent betaal je slechts € 14,95 per maand.',
        link: '/tarieven/bedrijf-met-personeel/'
      },
      {
        title: 'Hoe werkt de facturatie en betaling bij Employes?',
        id: 'facturatie-en-betaling',
        content: 'Wanneer je kiest voor Employes, dan word je verzocht om een automatische incasso af te geven. De abonnementskosten voor Employes worden vervolgens maandelijks geïncasseerd. Op een e-mailadres naar keuze ontvang je de maandelijkse digitale factuur.',
        link: ''
      },
      {
        title: 'Wat is de opzegtermijn?',
        id: 'opzegtermijn',
        content: 'Je hebt een opzegtermijn van 1 maand. De opzegging gaat in vanaf de eerste dag van de maand die volgt.',
        link: ''
      }
    ]
  },
  {
    name: 'Online inzicht werknemers',
    tagline: 'Werknemers',
    id: 'werknemers',
    subtext: 'Werknemers die loonstroken kwijt zijn of hun jaaropgave opnieuw willen ontvangen: dat vergt toch elke keer weer het nodige uitzoekwerk van HR. Met Employes is dat niet meer nodig!',
    questions: [
      {
        title: 'Kunnen werknemers online hun loonstrookjes bekijken?',
        id: 'loonstrook-ontvangen',
        content: 'Werknemers ontvangen een mail met een samenvatting van hun loonstrook, zodat zij die digitaal altijd bij de hand hebben. Willen zij graag de hele loonstrook bekijken? Dan kunnen ze deze bekijken via het Employes-portal. Hier hebben zij door middel van een persoonlijke inlog toegang tot een beperkt gedeelte van de informatie van jouw bedrijf.',
        link: ''
      },
      {
        title: 'Heeft Employes een mobiele app?',
        id: 'mobiele-app',
        content: 'Employes biedt een self-service app voor werknemers aan. Hiermee regelen werknemers alles rondom werk, salaris, verlof en verzuim snel vanaf mobiel.',
        link: '/blog/app-nu-beschikbaar/'
      },
      {
        title: 'Hoe ontvangen de werknemers een jaaropgave?',
        id: 'jaaropgave-ontvangen',
        content: 'De jaaropgave ontvangen werknemers via e-mail. Mochten zij die opnieuw willen ontvangen, dan kunnen zij inloggen op een afgeschermd gedeelte van het Employes-portal om de jaaropgave zelf te downloaden.',
        link: ''
      },
      {
        title: 'Kunnen werknemers zelf verlof aanvragen?',
        id: 'verlof-aanvragen',
        content: 'Ja, dit is mogelijk wanneer je voor het pakket Salaris-HR hebt gekozen. De werknemer kan dan via het Employes-portaal verlofverzoeken indienen en vrije dagen opnemen.',
        link: ''
      }
    ]
  },
  {
    name: 'Inclusief aangiftes',
    tagline: 'Belastingdienst',
    id: 'belastingdienst',
    subtext: 'Employes regelt je salariszaken van A tot Z. Dat geldt dus ook voor de aangiftes bij de Belastingdienst!',
    questions: [
      {
        title: 'Hoe werkt de loonaangifte?',
        id: 'hoe-werkt-loonaangifte',
        content: 'De loonaangifte verloopt geheel automatisch vanuit Employes. Je hoeft zelf geen aangifte te doen; dit wordt vanzelf voor je geregeld.',
        link: ''
      },
      {
        title: 'Hoe werkt de aanmelding bij de belastingdienst?',
        id: 'aanmelden-belastingdienst',
        content: 'Bedrijven die voor de eerste keer personeel aannemen, moeten zich aanmelden bij de Belastingdienst. Dit doe je door een loonheffingennummer aan te vragen. In Employes kun je een voorafingevuld formulier downloaden waarmee je eenvoudig een loonheffingnummer aanvraagt bij de Belastingdienst.',
        link: '/tools/aanvragen-loonbelastingnummer/'
      }
    ]
  },
  {
    name: 'Inclusief aanmeldingen',
    tagline: 'CAO/Pensioenen',
    id: 'CAOPensioenen',
    subtext: 'Ook de aanmelding bij het pensioenfonds en cao’s zijn onderdeel van de dagelijkse salariszaken. Kortom, die zitten standaard inbegrepen bij Employes.',
    questions: [
      {
        title: 'Welke pensioenen worden er ondersteunt in Employes?',
        id: 'pensioen-ondersteuning',
        content: 'In Employes ondersteunen we een ruim aantal pensioenfondsen. Tevens bieden wij koppelingen met diverse pensioenuitvoerders aan. Via de volgende link kun je bekijken welke CAOs en bijbehorende pensioenfondsen we ondersteunen.',
        link: 'https://support.employes.nl/nl/articles/3689950-welke-cao-s-ondersteunt-employes'
      },
      {
        title: 'In hoeverre ondersteunt Employes CAOs',
        id: 'cao-ondersteuning',
        content: 'Employes ondersteunt een groot aantal CAOs. Ben je benieuwd of jouw CAO beschikbaar is? Bekijk dan het volgende artikel.',
        link: 'https://support.employes.nl/nl/articles/3689950-welke-cao-s-ondersteunt-employes'
      },
      {
        title: 'Hoe gaat de aanmelding bij een pensioenfonds?',
        id: 'aanmelding-pensioenfonds',
        content: 'Werknemers kunnen worden aangemeld via de pensioenaangifte van jouw branche. De gegevens die in Employes staan zijn voldoende om de aangifte in te zenden. Wij ondersteunen graag om dit voor je te regelen.',
        link: ''
      }
    ]
  },
  {
    name: 'Uitstekende support',
    tagline: 'Klantenservice',
    id: 'klantenservice',
    subtext: '24 uur per dag, 7 dagen per week kun je op onze website de veelgestelde vragen en antwoorden inzien. Staat jouw vraag er niet tussen? Dan staan we je graag persoonlijk te woord.',
    questions: [
      {
        title: 'Hoe kan ik Employes bereiken bij vragen?',
        id: 'hoe-kan-ik-bereiken',
        content: 'Je kunt ons tijdens kantoortijden, van 9.00 tot 17.00 uur, telefonisch bereiken op 020 - 80 85 481 of via de online chat op onze website. Je kunt ook een mail sturen via het contactformulier of direct naar: info@employes.nl.',
        link: ''
      },
      {
        title: 'Kan ik met alle salarisvragen terecht bij Employes?',
        id: 'alle-vragen',
        content: 'Het team achter Employes is ervaren op het gebied van salaris én personeel. Als je vragen hebt over deze onderwerpen, zullen wij altijd ons best doen om je bij te staan.',
        link: ''
      }
    ]
  }
]

class FAQPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  componentDidMount() {
    const { data } = this.props
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setActive(event) {

    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  scrollToFAQSection(id) {
    const target = document.querySelector(`#${id}`)
    const scrollToTarget = target.offsetTop - 32
    window.scrollTo(0, scrollToTarget)
  }

  handleScroll() {
    const scrollPos = window.pageYOffset
    const cats = document.getElementById('categories').childNodes

    cats.forEach((item) => {
      const target = item.dataset['target']
      const refElem = document.getElementById(target)
      const refTop = refElem.offsetTop - 120
      const refHeight = refElem.getBoundingClientRect().height + 128

      if (refTop <= scrollPos && refTop + refHeight > scrollPos) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="faq animated fadeInPage">

          <Helmet>
            <title>Veelgestelde vragen salarisadministratie | Employes</title>
            <meta name="description" content="Bekijk het antwoord op de meest gestelde vragen over salarisadministratie.In onze veelgestelde vragen zetten we de antwoorden voor je op een rij. Beginnen met salarisverwerking? Employes helpt je op weg." />
            <meta itemprop="name" content="Veelgestelde vragen salarisadministratie | Employes" />
            <meta itemprop="description" content="Bekijk het antwoord op de meest gestelde vragen over salarisadministratie. Beginnen met salarisverwerking? Employes helpt je op weg."/>
            <meta itemprop="image" content="/static/meta-img.png" />

              <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [
                    {
                      "@type": "Question",
                      "name": "Hoe lang is de proefperiode precies?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Na het aanmaken van een account, kun je Employes zo lang als je wilt gratis en vrijblijvend uitproberen in de vorm van een demo-versie. Pas wanneer je aangeeft dat je daadwerkelijk met Employes aan de slag wil, zetten we je account op actief."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Hoe ga ik van start met Employes?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Nadat je een account hebt aangemaakt, begeleiden we je stap voor stap door de applicatie. Tijdens deze stappen, kun je alle relevante gegevens voor je salarisadministratie invullen. Daarna kun je binnen een paar minuten al de eerste loonstrook bekijken. Wil je daarna overstappen? Dan kun je aangeven dat je je account wilt activeren."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Hoe werkt het overstappen?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Om ervoor te zorgen dat je met Employes aan de slag kunt, hebben wij de volgende informatie nodig: de meest recente loonstroken van je werknemer(s). Ons team regelt dat die informatie in de applicatie wordt ingeladen zodat je de salaris van de volgende maand automatisch in Employes kunt verwerken."
                      }
                    },
                    {
                      "@type": "Question",
                      "name": "Wat zijn de kosten voor een bedrijf?",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Voor bedrijven met personeel zijn er twee pakketten: Salaris & HR: € 39 per maand + € 6 per werknemer per maand. en\tSalaris: € 25 per maand + € 5 per werknemer per maand. Het Salaris & HR-pakket bevat uitgebreidere functionaliteiten. Als je hiervoor kiest, dan kan HR bijvoorbeeld arbeidscontracten genereren en kunnen werknemers online hun verlofdagen doorgeven. En dat alles op één vaste plek: in Employes."
                      }
                    }
                  ]
                }
              `}</script>

          </Helmet>

          <header className="header padding-xxxl">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-8">
                  <h1>Veelgestelde vragen</h1>
                  <p className="streamer">Bekijk hieronder het antwoord op de meest gestelde vragen.</p>
                </div>

                <div className="hero-illustration">
                  <img src={faqHeader} alt="hero illustratie faq"/>
                </div>
              </div>
            </div>
          </header>

          <section className="overview padding-xxl">
            <div className="container-md">
              <div className="grid">
                <div className="col-3">
                  <p className="eyebrow">Categorieën</p>
                  <ul className="categories" id="categories">
                    {allCategories.map((category, idx) => (
                      <li
                        key={idx}
                        className="category-item"
                        data-target={category.id}
                        onClick={e => this.scrollToFAQSection(category.id)}
                      >
                        <h6>{category.tagline}</h6>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-9">
                  {allCategories.map((category, idx) => (
                    <div className="faq-block margin-xxl-bottom" id={category.id} key={idx}>
                      <p className="eyebrow">{category.tagline}</p>
                      <h2>{category.name}</h2>
                      <p className="subtitle">{category.subtext}</p>

                      {category.questions.map((question, qdx) => (
                        <FaqDropdown
                          question={question}
                          idx={qdx}
                          setActive={this.setActive}
                          activeID={this.state.activeID}
                          key={qdx}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default FAQPage
